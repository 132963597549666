<template>
  <div class="container">
    <h1 class="title is-2">Crea evento</h1>

    <form>
      <div class="field">
        <label class="label" for="category">Seleziona categoria</label>
        <div class="control">
          <div class="select">
            <select name="category" id="category" v-model="event.category">
              <option v-for="category in categories" :key="category" :value="category">{{ category }}</option>
            </select>
          </div>
        </div>
      </div>

      <h3 class="subtitle is-3">Nome e descrizione evento</h3>
      <BaseInput label="Nome" type="text" v-model="event.name" />

      <BaseInput label="Descrizione" type="text" v-model="event.description" />

      <h3 class="subtitle is-3">Dove si svolgerà l'evento?</h3>
      <BaseInput label="Luogo evento" type="text" v-model="event.location" />

      <h3 class="subtitle is-3">Animali ammessi?</h3>
      <div class="field">
        <div class="control">
          <label class="radio">
            <input type="radio" name="animals" v-model="event.animals" :value="1">
            Si
          </label>
          <label class="radio">
            <input type="radio" name="animals" v-model="event.animals" :value="0">
            No
          </label>
        </div>
      </div>

      <h3 class="subtitle is-3">Extra</h3>
      <div class="field">
        <div class="control">
          <label class="checkbox">
            <input type="checkbox" v-model="event.extra.music">
            Musica live
          </label>
        </div>
      </div>

      <div class="field">
        <div class="control">
          <label class="checkbox">
            <input type="checkbox"  v-model="event.extra.show">
            Spettacolo
          </label>
        </div>
      </div>

      <button class="button is-info" type="submit">Crea</button>
    </form>

    <hr />
    <pre>{{ event }}</pre>
  </div>
</template>

<script>
import BaseInput from '@core/components/form-fields/BaseInput.vue'
export default {
  components: {
    BaseInput
  },
  data() {
    return {
      categories: [
        'Natura',
        'Educazione',
        'Comunità',
        'Cibo',
        'Musica',
        'Cultura',
        'Ambiente'
      ],
      event: {
        name: '',
        description: '',
        category: '',
        location: '',
        animals: 1,
        extra: {
          music: false,
          show: false
        }
      }
    }
  }
}
</script>

<style lang="scss">

</style>
